section.dark.primary {
	background-color: #207b20;
}

.navbar-default .navbar-brand img {
     height:20px;
}

.is-it-on {
	background-color: #207b20;
	display: inline-block;
	padding: 1em 2em;
	margin-top: 1em;
}

.title-block h1 {
	font-weight: bold;
}

.hero {
	display: flex;
	align-items: center;
}

.next-sale p {
	font-size: 40px;
}
